<template>
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49974 12.7285L7.49974 2.27148M7.49974 2.27148L2.9248 6.84642M7.49974 2.27148L12.0747 6.84642" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconArrowUp'
}
</script>
